
.grants-page {
    max-width: 1400px;
}

.grant-edit-btn {
    margin-left: 8px;
}

.manager-limits .cooldown-bar {
    max-width: 700px;
    width: 70%;
}

.grants-page .create-btn {
    float: right;
    margin: 4px 0;
}

.grant-manager-dialog .field-label {
    display: inline-block;
    margin-top: 6px;
}